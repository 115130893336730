<template>
    <div class="event-card row" v-if="event">
        <img :src="eventImage" class="drop-shadow">
        <div class="card-body column">
            <div class="card-header row">
                <div class="column" :class="[{'title-with-audio': event.audio != null}]">   
                    <span>{{event.date}}</span>
                    <span v-tooltip="event.title || ''">{{event.title}}</span>
                </div>
                <AudioToggle v-if="event.audio && isTimeLineEventCardDisplayed"
                    :white-background="true"
                     :key="event.id" :audio="event.audio"/>
            </div>
            <p class="custom-scrollbar" v-html="event.longDescription"></p>
        </div>
    </div>
</template>
<script>
import AudioToggle from "@/common/AudioToggle.vue";

export default {
    name: 'TimeLineEventCard',
    components: {
        AudioToggle
    }, 
    computed:{
        event(){
            return this.$store.state.currentTimeLineEvent
        }, 
        eventImage(){
            return this.getImageUrl(this.event.imageName)
        }, 
        isTimeLineEventCardDisplayed(){
            return this.$store.state.isTimeLineEventCardDisplayed
        },
    }
}
</script>
<style lang="less">
.event-card{
    margin: auto;
    margin-top: 47px;
    img{
        max-height: 303px;
        max-width: 464px;
        height: auto;
        margin-left: auto;
    }
    .card-body{
        height: auto;
        width: 520px;
        margin-left: 16px;
        margin-right: auto;
        background-color: #FFFFFF;
        box-shadow: 0 4px 20px 0 rgba(0,0,0,0.2);
        .card-header{
            min-height: 87px;
            width: 100%;
            background-color: #0A1554;
            padding: 11px 24px;
            box-sizing: border-box;
            > div{
                width: 90%;
                max-width: 90%;

                span{
                    margin-top: 8px;
                    color: #FFFFFF;
                    font-size: 19.64px;
                    font-weight: bold;
                    letter-spacing: 0.25px;
                    line-height: 24px;
                    text-align: center;
                }
                span:nth-child(2){
                    font-size: 23.92px;
                    font-weight: 500;
                    line-height: 29px;
                    text-align: center;
                    white-space: normal;
                    height: auto;
                    max-height: 90px;
                }
            }

            .title-with-audio{
                width: 90%;
                max-width: 90%;
                max-height: 120px;
            }

            .audio-toggle{
                margin-top: 20px;
            }
        }
        >p{
            margin-block-start: unset;
            margin-block-end: unset;
            height: auto;
            width: 90%;
            max-width: 90%;
            color: #000;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            letter-spacing: 0.5px;
            line-height: 24px;
            padding: 16px 24px;
            p:nth-child(1){
                margin-block-start: unset;
                margin-block-end: unset;
            }
        }
    }
    transition: opacity 2s;
}
</style>
