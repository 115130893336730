<template>
    <div class="time-line column" v-if="timeline && events.length > 0">
        <div class="timeline-background"  :style="{backgroundColor: timelineBackGround}"></div>
        <span> <img :src="infoIcon"  @click="showTeacherPage"> {{$t('timeline')}}</span>
        <div class="column"  :class="{'time-line-hidden': isTimeLineEventCardDisplayed}">
            <div ref="events" class="events-container row custom-scrollbar" 
                :key="timelineBackGround"
                :style="{color: timelineBackGround}">
                <event-blurb v-for="event in events" :key="event.id"
                v-on:eventChanged="selectedEventChanged" :event="event" />
            </div>
        </div>
        <div class="column"  :style="{'display': isTimeLineEventCardDisplayed ? 'initial' : 'none'}">
            <event-card/>
            <button class="btn round secondary back" @click="hideCard">{{$t('back')}}</button>
        </div>
        <vue-slider  ref="slider" class="slider" @dragging="sliderPositionChanged" :disabled="isTimeLineEventCardDisplayed"
         @change="sliderPositionChanged" 
        :marks="sliderMarks" :data="sliderPoints"
         v-model="sliderPosition"
        :tooltip="'none'" :min="0" :max="maxOrder"
        :process-style="{ backgroundColor: ' #11B800', height: '4px'}"/>        
        
        <div v-if="!isTimeLineEventCardDisplayed" class="timeline-directions row">
          <AudioToggle v-if="timelineAudio" :white-background="true" :audio="timelineAudio" />
          <p>{{$t('timelineDirections')}}</p>
        </div>
    </div>
    <span v-else class="centered">{{$t('noContentToDisplayMessage')}}</span>
    
</template>
<script>
import TimeLineEventBlurb from '@/components/TimeLine/TimeLineEventBlurb.vue'
import TimeLineEventCard from '@/components/TimeLine/TimeLineEventCard.vue'
import VueSlider from 'vue-slider-component'
import '@/assets/TexasHistorySliderTheme.css'
import Factory from '@/utils/ContentFactory.js'
import AudioToggle from '@/common/AudioToggle.vue'

export default {
    name: 'TimeLine',
    components:{
        'event-card':TimeLineEventCard,
        'event-blurb': TimeLineEventBlurb, 
        VueSlider, 
        AudioToggle
    },
    data(){
        return{
            sliderPosition: 1,
        }
    },
    computed: {
        unit(){
            return this.$store.state.currentUnit
        },
        timeline(){
            return this.$store.state.timeline
        }, 
        events(){
            return this.timeline.blurbs
        }, 
        currentTimeLineEvent(){
            return this.$store.state.currentTimeLineEvent
        }, 
        isTimeLineEventCardDisplayed(){
            return this.$store.state.isTimeLineEventCardDisplayed
        },
        infoIcon(){
            return require('@/assets/icons/ic_info.svg')
        }, 
        maxOrder(){
            return  this.events[this.events.length -1].order 
        },
        sliderPoints(){
            let sliderPoints = []
            this.events.forEach((e)=>{
                sliderPoints.push(e.date)
            })
            return sliderPoints
        }, 
        sliderMarks(){
            let marks = this.sliderPoints
            if(this.events.length < 10)
                return marks
                
            let sliderMarks =  marks.length % 2 === 0
                ? marks
                    .filter((e, index) => index === 0
                        ||index === marks.length -1
                        || index%2 === 0 )

                : marks
                    .filter((e, index) => index === 0 
                        || index === marks.length -1 
                        || index%2 != 0 )

            return sliderMarks
        }, 
        timelineBackGround(){
            let color =  this.hexToRgb(this.timeline.backgroundColor)
            if(!this.timeline || !color)
                return '#fff'
            return  `rgba(${color.r}, ${color.g}, ${color.b}, ${(this.timeline.opacity/100) || 1})`
        },
        timelineAudio(){
          return new Factory.GetTimelineDirectionAudios()
        }
    },
    created(){
        this.$store.dispatch('loadTimeLine', this.$route.params.unitId)
    }, 
    methods:{
        hideCard(){
            this.$store.commit('updateIsTimeLineEventCardDisplayedStatus', false)
        }, 
        updateEventsContainerScrollPosition(position){
            //Following conditional adjust position to center blurb on the screen
             position > this.$refs.events.offsetWidth && position < (this.$refs.events.offsetWidth /2 )
                 ? position = this.$refs.events.scrollLeft
                 : position -=(this.$refs.events.offsetWidth -170)/2

            if(!this.$refs.events)
                return
            this.$refs.events.scrollTo({
                top: 0,
                left: position,
                behavior: 'smooth'
            })
        },
        getScrollPosition(selectedEvent){
            let indexOfSelectedEvent = this.events.indexOf(selectedEvent)
            return (this.$refs.events.scrollWidth / this.events.length) * indexOfSelectedEvent
        },
        sliderPositionChanged(eventDate){            
            let selectEventInSlider = this.events.find(e => e.date == eventDate) 
            let position = this.getScrollPosition(selectEventInSlider)
            this.updateEventsContainerScrollPosition(position)
            this.$store.commit('updateCurrentTimeLineEvent', selectEventInSlider)
        }, 
        selectedEventChanged(e){
            let indexOfPoint = this.sliderPoints.indexOf(e.date)
            this.sliderPositionChanged(e.date)
            this.$refs.slider.setIndex(indexOfPoint)
        }, 
        hexToRgb(hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },
        showTeacherPage(){
            if(this.$store.state.isPreviewModeActive)
                return 
            this.$router.push({ name: 'TeacherPageUnit', params: { 
                teacherPageId: this.unit.skillsId
              }, query:{
                title: this.unit.name
              }})
        }
    },
    mounted(){
        this.startTrackingPage({ sectionName: "TimeLine" });
        this.$store.commit("setAnalyzedManagementSection", "TimeLine");
        
        this.$store.commit('updateCurrentTimeLineEvent', null)
        this.$store.commit('updateIsTimeLineEventCardDisplayedStatus', false)
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "TimeLine" });
    }
}
</script>
<style lang="less" scoped>
.time-line{
    position: relative;
    justify-content: center;
    margin: 0 auto;
    z-index: 0;
    margin-top: 104px;
    max-height: calc(100vh - 104px);
    opacity: 1;
    transition: opacity 1s;
    // max-width: 1280px;
    .time-line-hidden{
        display: none;
        opacity: 0;
    }

    .timeline-background{
        z-index: -2;
        position: fixed;
        top: 0;
        left: 0;
        min-height: 100vh;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    span{
        margin-top: 16px;
        margin-left: 88px;
        min-height: 41px;
        max-width: 900px;
        color: rgba(0,0,0,0.6);
        font-size: 33.64px;
        font-weight: 600;
        letter-spacing: 0.25px;
        line-height: 41px;
        text-transform: capitalize;
    }

    .events-container{
        margin-top: 40px;
        justify-content: space-between;
        overflow-x: hidden;
        padding-bottom: 16px;
        padding-right: 100px;
        padding-left: 100px;
        &::before, &::after{
            position: absolute;
            content: '';
            width:56px;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2;
            background-image: linear-gradient(90deg, currentColor 0%, currentColor 44.01%, 
            rgba(255,255,255,0) 100%);
        }
        &::after{
            right: 0;
            top: 0;
            left: unset;
            transform: scaleX(-1);
        }
    }

    .slider{
        max-width: 80%;
        min-width: 80%;
        margin: auto;
        margin-top: 54px;
    }

    .btn.back{
        margin-top: 40px;
        margin-left: 120px;
    }

    .timeline-directions{
        margin: 70px auto auto 145px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        white-space: normal;
        color: #000;

        .audio-toggle{
          margin-right: 16px;
          align-self: center;
        }
    }
}
.slider{
    .vue-slider-dot-handle {
        background-color: #EEEEEE;
        height: 22px;
        width: 16px;
        transform: translate(0, -20%);
    }
}

</style>

